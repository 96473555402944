import './shared';
import * as library from "./library";

// Images not in CSS
library.importAllImages(require.context('./imgs/', true, /\.(png|jpe?g|gif|webp|ico|svg)(\?.*)?$/));

// import 'bootstrap/dist/css/bootstrap.css';
import './theme/front.scss';

import './modules/front';
import naja from "naja";


// Webpack HMR
if (module.hot) {
	module.hot.accept();
}

const modalExtension = {
	initialize(naja) {
		naja.snippetHandler.addEventListener('afterUpdate', (event) => {
			const { snippet } = event.detail;
			if (snippet.classList.contains('modal')) {
				$(snippet).modal('show');
			}
		});
	}
}

document.addEventListener('DOMContentLoaded', naja.registerExtension(modalExtension));
